import {
  GET_LEAVE_REQUEST,
  GET_LEAVE_SUCCESS,
  GET_LEAVE_FAILURE,
  GET_LEAVE_EXTENSION_REQUEST,
  GET_LEAVE_EXTENSION_SUCCESS,
  GET_LEAVE_EXTENSION_FAILURE,
  GET_ONLEAVE_REQUEST,
  GET_ONLEAVE_SUCCESS,
  GET_ONLEAVE_FAILURE,
  GET_SCHEDULEDLEAVE_REQUEST,
  GET_SCHEDULEDLEAVE_SUCCESS,
  GET_SCHEDULEDLEAVE_FAILURE,
  CREATE_LEAVE_REQUEST,
  CREATE_LEAVE_SUCCESS,
  CREATE_LEAVE_FAILURE,
  CREATE_LEAVE_SETTINGS_REQUEST,
  CREATE_LEAVE_SETTINGS_SUCCESS,
  CREATE_LEAVE_SETTINGS_FAILURE,
  GET_LEAVE_SETTINGS_REQUEST,
  GET_LEAVE_SETTINGS_SUCCESS,
  GET_LEAVE_SETTINGS_FAILURE,
  GET_LEAVE_HISTORY_REQUEST,
  GET_LEAVE_HISTORY_SUCCESS,
  GET_LEAVE_HISTORY_FAILURE,
  CREATE_LEAVE_RECALL_REQUEST,
  CREATE_LEAVE_RECALL_SUCCESS,
  CREATE_LEAVE_RECALL_FAILURE,
  EDIT_LEAVE_REQUEST,
  EDIT_LEAVE_SUCCESS,
  EDIT_LEAVE_FAILURE,
  LEAVE_DETAIL_REQUEST,
  LEAVE_DETAIL_SUCCESS,
  LEAVE_DETAIL_FAILURE,
  ADD_HOLIDAY_REQUEST,
  ADD_HOLIDAY_SUCCESS,
  ADD_HOLIDAY_FAILURE,
  EDIT_HOLIDAY_REQUEST,
  EDIT_HOLIDAY_SUCCESS,
  EDIT_HOLIDAY_FAILURE,
  GET_HOLIDAY_REQUEST,
  GET_HOLIDAY_SUCCESS,
  GET_HOLIDAY_FAILURE,
  DELETE_LEAVE_REQUEST,
  DELETE_LEAVE_FAILURE,
  DELETE_LEAVE_SUCCESS,
  DELETE_LEAVE_SETTING_REQUEST,
  DELETE_LEAVE_SETTING_FAILURE,
  DELETE_LEAVE_SETTING_SUCCESS,
  EDIT_LEAVE_SETTING_REQUEST,
  EDIT_LEAVE_SETTING_FAILURE,
  EDIT_LEAVE_SETTING_SUCCESS,
  DELETE_HOLIDAY_REQUEST,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_FAILURE,
} from 'redux/actionTypes/leaveManagementTypes';

const initialState = {
  loading: false,
  leaveRequests: {},
  detail: null,
  hollidays: {},
  leaveSettings: {},
};

/**
 *leave Reducer
 *
 * @export
 */
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_LEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveRequests: payload?.requests,
        leaveRecalls: payload?.recalls,
      };
    case GET_LEAVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_LEAVE_EXTENSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEAVE_EXTENSION_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveExtensions: payload?.extensions,
      };
    case GET_LEAVE_EXTENSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_ONLEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ONLEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        onLeave: payload?.onLeave,
      };
    case GET_ONLEAVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_SCHEDULEDLEAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SCHEDULEDLEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        onLeave: payload?.onLeave,
      };
    case GET_SCHEDULEDLEAVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_LEAVE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case CREATE_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_LEAVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_LEAVE_SETTINGS_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case CREATE_LEAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_LEAVE_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CREATE_LEAVE_RECALL_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case CREATE_LEAVE_RECALL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_LEAVE_RECALL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_LEAVE_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveSettings: payload.leaveSettings,
      };
    case GET_LEAVE_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_LEAVE_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LEAVE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        leaveHistory: payload.leaveHistory,
      };
    case GET_LEAVE_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EDIT_LEAVE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case EDIT_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_LEAVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LEAVE_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LEAVE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: payload?.detail,
      };
    case LEAVE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_HOLIDAY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
        hollidays: payload.hollidays,
      };
    case GET_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ADD_HOLIDAY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case ADD_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EDIT_HOLIDAY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case EDIT_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_LEAVE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case DELETE_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_LEAVE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_LEAVE_SETTING_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case DELETE_LEAVE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_LEAVE_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case EDIT_LEAVE_SETTING_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case EDIT_LEAVE_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_LEAVE_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case DELETE_HOLIDAY_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
