export const GET_LEAVE_REQUEST = 'GET_LEAVE_REQUEST';
export const GET_LEAVE_SUCCESS = 'GET_LEAVE_SUCCESS';
export const GET_LEAVE_FAILURE = 'GET_LEAVE_FAILURE';

export const GET_LEAVE_EXTENSION_REQUEST = 'GET_LEAVE_EXTENSION_REQUEST';
export const GET_LEAVE_EXTENSION_SUCCESS = 'GET_LEAVE_EXTENSION_SUCCESS';
export const GET_LEAVE_EXTENSION_FAILURE = 'GET_LEAVE_EXTENSION_FAILURE';

export const GET_ONLEAVE_REQUEST = 'GET_ONLEAVE_REQUEST';
export const GET_ONLEAVE_SUCCESS = 'GET_ONLEAVE_SUCCESS';
export const GET_ONLEAVE_FAILURE = 'GET_ONLEAVE_FAILURE';

export const GET_SCHEDULEDLEAVE_REQUEST = 'GET_SCHEDULEDLEAVE_REQUEST';
export const GET_SCHEDULEDLEAVE_SUCCESS = 'GET_SCHEDULEDLEAVE_SUCCESS';
export const GET_SCHEDULEDLEAVE_FAILURE = 'GET_SCHEDULEDLEAVE_FAILURE';

export const CREATE_LEAVE_REQUEST = 'CREATE_LEAVE_REQUEST';
export const CREATE_LEAVE_SUCCESS = 'CREATE_LEAVE_SUCCESS';
export const CREATE_LEAVE_FAILURE = 'CREATE_LEAVE_FAILURE';

export const EDIT_LEAVE_REQUEST = 'EDIT_LEAVE_REQUEST';
export const EDIT_LEAVE_SUCCESS = 'EDIT_LEAVE_SUCCESS';
export const EDIT_LEAVE_FAILURE = 'EDIT_LEAVE_FAILURE';

export const LEAVE_DETAIL_REQUEST = 'LEAVE_DETAIL_REQUEST';
export const LEAVE_DETAIL_SUCCESS = 'LEAVE_DETAIL_SUCCESS';
export const LEAVE_DETAIL_FAILURE = 'LEAVE_DETAIL_FAILURE';

export const GET_HOLIDAY_REQUEST = 'GET_HOLIDAY_REQUEST';
export const GET_HOLIDAY_SUCCESS = 'GET_HOLIDAY_SUCCESS';
export const GET_HOLIDAY_FAILURE = 'GET_HOLIDAY_FAILURE';

export const ADD_HOLIDAY_REQUEST = 'ADD_HOLIDAY_REQUEST';
export const ADD_HOLIDAY_SUCCESS = 'ADD_HOLIDAY_SUCCESS';
export const ADD_HOLIDAY_FAILURE = 'ADD_HOLIDAY_FAILURE';

export const EDIT_HOLIDAY_REQUEST = 'EDIT_HOLIDAY_REQUEST';
export const EDIT_HOLIDAY_SUCCESS = 'EDIT_HOLIDAY_SUCCESS';
export const EDIT_HOLIDAY_FAILURE = 'EDIT_HOLIDAY_FAILURE';

export const DELETE_HOLIDAY_REQUEST = 'DELETE_HOLIDAY_REQUEST';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE';

export const CREATE_LEAVE_SETTINGS_REQUEST = 'CREATE_LEAVE_SETTINGS_REQUEST';
export const CREATE_LEAVE_SETTINGS_SUCCESS = 'CREATE_LEAVE_SETTINGS_SUCCESS';
export const CREATE_LEAVE_SETTINGS_FAILURE = 'CREATE_LEAVE_SETTINGS_FAILURE';

export const GET_LEAVE_SETTINGS_REQUEST = 'GET_LEAVE_SETTINGS_REQUEST';
export const GET_LEAVE_SETTINGS_SUCCESS = 'GET_LEAVE_SETTINGS_SUCCESS';
export const GET_LEAVE_SETTINGS_FAILURE = 'GET_LEAVE_SETTINGS_FAILURE';

export const GET_LEAVE_HISTORY_REQUEST = 'GET_LEAVE_HISTORY_REQUEST';
export const GET_LEAVE_HISTORY_SUCCESS = 'GET_LEAVE_HISTORY_SUCCESS';
export const GET_LEAVE_HISTORY_FAILURE = 'GET_LEAVE_HISTORY_FAILURE';

export const CREATE_LEAVE_RECALL_REQUEST = 'CREATE_LEAVE_RECALL_REQUEST';
export const CREATE_LEAVE_RECALL_SUCCESS = 'CREATE_LEAVE_RECALL_SUCCESS';
export const CREATE_LEAVE_RECALL_FAILURE = 'CREATE_LEAVE_RECALL_FAILURE';

export const DELETE_LEAVE_REQUEST = 'DELETE_LEAVE_REQUEST';
export const DELETE_LEAVE_SUCCESS = 'DELETE_LEAVE_SUCCESS';
export const DELETE_LEAVE_FAILURE = 'DELETE_LEAVE_FAILURE';

export const DELETE_LEAVE_SETTING_REQUEST = 'DELETE_LEAVE_SETTING_REQUEST';
export const DELETE_LEAVE_SETTING_SUCCESS = 'DELETE_LEAVE_SETTING_SUCCESS';
export const DELETE_LEAVE_SETTING_FAILURE = 'DELETE_LEAVE_SETTING_FAILURE';

export const EDIT_LEAVE_SETTING_REQUEST = 'EDIT_LEAVE_SETTING_REQUEST';
export const EDIT_LEAVE_SETTING_SUCCESS = 'EDIT_LEAVE_SETTING_SUCCESS';
export const EDIT_LEAVE_SETTING_FAILURE = 'EDIT_LEAVE_SETTING_FAILURE';

export const ADD_LEAVE_SETTING_REQUEST = 'ADD_LEAVE_SETTING_REQUEST';
export const ADD_LEAVE_SETTING_SUCCESS = 'ADD_LEAVE_SETTING_SUCCESS';
export const ADD_LEAVE_SETTING_FAILURE = 'ADD_LEAVE_SETTING_FAILURE';

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
