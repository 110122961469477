/* eslint-disable camelcase */
import axios from 'config/axios';
import { toast } from 'react-toastify';
import { generateError } from 'utils/customHooks';
import * as actionTypes from '../actionTypes/adminAppraisal';

export const getAppraisal =
  (paginate, page = 1, pagesize = 50) =>
  async (dispatch) => {
    const url = paginate || `/appraisal/?page=${page}&page_size=${pagesize}`;
    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_APPRAISAL_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getCandidatesAppraisalList =
  (employeeId, paginate, page = 1, pagesize = 50) =>
  async (dispatch) => {
    const url =
      paginate ||
      `/employee/${employeeId}/appraisals/?page=${page}&page_size=${pagesize}`;
    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_APPRAISAL_SUCCESS,
        payload: response,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getCandidatesCurrentAppraisalList =
  (employeeId, paginate, page = 1, pagesize = 50) =>
  async (dispatch) => {
    const url =
      paginate ||
      `/employee/${employeeId}/appraisals/current/?page=${page}&page_size=${pagesize}`;
    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_CURRENT_APPRAISAL_SUCCESS,
        payload: response,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getSingleAppraisal = (id) => async (dispatch) => {
  const url = `/appraisal/${id}`;
  try {
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_SINGLE_APPRAISAL_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const getEmployeeKpiResponse =
  (employeeId, appraisal, kpi_id, status = 'PENDING') =>
  async (dispatch) => {
    const url = `/appraisal/employee_kpi/?employee__id=${employeeId}&kpi__appraisal__id=${appraisal}&status=${status}`;
    dispatch({
      type: actionTypes.GET_APPRAISAL_SUCCESS,
      payload: [],
    });
    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_APPRAISAL_SUCCESS,
        payload: response.data,
      });
      dispatch(getSingleAppraisal(appraisal));
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getEmployeeKpiResponseAdmin =
  (employeeId, appraisal, kpi_id) => async (dispatch) => {
    const url = `/appraisal/employee_kpi/?employee__id=${employeeId}&kpi__appraisal__id=${appraisal}`;
    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_EMPLOYEES_APPRAISAL_SINGLE_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getEmployeeKpi =
  (employeeId, appraisal, kpi_id, status = 'PENDING') =>
  async (dispatch) => {
    const url = `/appraisal/employee_kpi/?employee__id=${employeeId}&kpi__appraisal__id=${appraisal}&kpi__id=${kpi_id}&status=${status}`;
    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_APPRAISAL_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };
export const getSupervisorEmployeeKpi =
  (employeeId, appraisal, kpiId) => async (dispatch) => {
    const url = `/appraisal/employee_kpi/?employee__id=${employeeId}&kpi__appraisal__id=${appraisal}&kpi__id=${kpiId}&status=SUBMITTED`;
    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_APPRAISAL_SUCCESS,
        payload: response.data,
      });
      return response.data.results[0];
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };
export const getEmployeeKpiForSupervisor = (employeeId) => async (dispatch) => {
  const url = `/appraisal/employee_kpi/${employeeId}`;
  try {
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_APPRAISAL_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const getAppraisalSearch = (search) => async (dispatch) => {
  const url = `/appraisal/?search=${search}`;
  try {
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_APPRAISAL_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const createAppraisal = (data) => async (dispatch) => {
  try {
    await axios.post('/appraisal/', data);
    toast.success('appraisal created successfully');
    dispatch(getAppraisal());
  } catch ({ response }) {
    if (response?.status) {
      generateError(
        response?.data?.message ||
          response?.data?.errors ||
          response?.data?.non_field_errors
      );
      return;
    }
    return toast.error('Network Error');
  }
};

export const initiateAppraisal = (id, data) => async (dispatch) => {
  try {
    await axios.post(`/appraisal/${id}/initiate/`, data);
    toast.success('appraisal iniitiated successfully');
  } catch ({ response }) {
    if (response?.status) {
      generateError(
        response?.data?.message ||
          response?.data?.errors ||
          response?.data?.non_field_errors
      );
      return;
    }
    return toast.error('Network Error');
  }
};

export const updateAppraisal = (id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(`/appraisal/${id}/`, data);
    dispatch({
      type: actionTypes.CREATE_APPRAISAL_SUCCESS,
      payload: response.data,
    });
    dispatch(getAppraisal());
    window.location.href = '/appraisal-success';
    toast.success('Appraisal updated successfully');
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const updateEmployeeAppraisalForSupervisor = (id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(`/appraisal/employee_kpi/${id}/`, data);
    dispatch({
      type: actionTypes.CREATE_APPRAISAL_SUCCESS,
      payload: response.data,
    });

    toast.success('Appraisal updated successfully');
    window.location.href = '/appraisal-success';
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const deleteAppraisal = (id) => async (dispatch) => {
  try {
    const response = await axios.delete(`/appraisal/${id}/`);
    dispatch({
      type: actionTypes.CREATE_APPRAISAL_SUCCESS,
      payload: response.data,
    });
    dispatch(getAppraisal());
    toast.success('Appraisal deleted successfully');
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const getKpi =
  (appraisal_id, paginate, page = 1, pagesize = 2) =>
  async (dispatch) => {
    const url =
      paginate ||
      `/appraisal/kpi/?appraisal=${appraisal_id}&page=${page}&page_size=${pagesize}`;

    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_KPI_OBJECTIVES_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const updateEmployeeAppraisalResponse =
  (data, status = 'APPRAISAL SUBMITTED', modal) =>
  async (dispatch) => {
    let objective_data = [];
    const existingObjectives = data.objectives.filter((each) => each.id);
    for (const objective of existingObjectives) {
      if (objective.id) {
        objective_data.push({
          id: objective?.id,
          text: objective?.text,
          weight: objective?.weight,
          rating: {
            employee_rating: objective?.rating?.employee_rating || 0,
            employee_comment: objective?.rating?.employee_comment || 0,
            employee: data.employee,
            line_manager_rating: objective?.rating?.line_manager_rating || null,
            line_manager_comment: objective?.rating?.line_manager_comment || null,
          },
        });

        // objectivesRequestPromises.push(
        //   axios.patch(`/appraisal/objectives/${objective.id}/`, {
        //     ...objective_data,
        //     appraisal: data.appraisal_id,
        //   })
        // );
      }
    }

    let objective_array = {
      objectives: objective_data,
    };
    await axios.post(`/appraisal/update_objectives/`, objective_array);

    const {
      validAppraisal_employee_id,
      validAppraisal_workforce_id,
      objectives,
      ...rest
    } = data;
    await axios.patch(`/appraisal/employee_kpi/${validAppraisal_employee_id}/`, {
      ...rest,
      status,
    });
    axios.patch(`/appraisal/employee_kpi/${validAppraisal_workforce_id}/`, {
      ...rest,
      status,
    });

    window.location.href = '/appraisal-success';
  };

export const updateEmployeeAppraisal = (data) => async (dispatch) => {
  try {
    const existingObjectives = data.objectives.filter((each) => each.id);
    const newObjectives = data.objectives.filter((each) => !each.id);
    const objectivesRequestPromises = [];
    const deletePromises = [];
    for (const objective of existingObjectives) {
      if (objective.id) {
        objectivesRequestPromises.push(
          axios.patch(`/appraisal/objectives/${objective.id}/`, {
            ...objective,
            appraisal: data.appraisal_id,
          })
        );
      }
    }
    for (const deleteId of data.deletedIds) {
      deletePromises.push(axios.delete(`/appraisal/objectives/${deleteId}/`));
    }

    const payload = newObjectives
      ? {
          ...data,
          employee: data.employee,
          objectives: newObjectives,
        }
      : {
          ...data,
          employee: data.employee,
        };
    const employee_response = await axios.patch(
      `/appraisal/employee_kpi/${data.employee_kpi_id}/`,
      payload
    );
    await axios.post(`/appraisal/${data.appraisal_id}/submit_kpi/`);
    window.location.href = '/appraisal-success';
    dispatch(
      getEmployeeKpi(data.employee, data.appraisal_id, employee_response.data.kpi.id)
    );
  } catch (error) {
    toast.error('Failed to update employee appraisal');
  }
};

export const createKpi = (appraisal, data) => async (dispatch) => {
  try {
    const response = await axios.post('/appraisal/kpi/', data);

    dispatch({
      type: actionTypes.CREATE_KPI_OBJECTIVES_SUCCESS,
      payload: response.data,
    });
    dispatch(getKpi(appraisal));
    toast.success('Kpi created successfully');

    return response;
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const editKpi = (id, data, appraisal) => async (dispatch) => {
  try {
    const response = await axios.patch(`/appraisal/kpi/${id}/`, data);
    dispatch({
      type: actionTypes.CREATE_KPI_OBJECTIVES_SUCCESS,
      payload: response.data,
    });

    dispatch(getKpi(data?.appraisal));
    toast.success('Kpi updated successfully');
    return response;
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const createObjectives = (id, data, appraisal) => async (dispatch) => {
  try {
    await axios.patch(`/appraisal/kpi/${id}/`, data);
    dispatch(getKpi(data?.appraisal));
    toast.success('Kpi updated successfully');
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const editObjectives = (id, data, appraisal) => async (dispatch) => {
  try {
    await axios.patch(`/appraisal/objectives/${id}/`, data);

    dispatch(getKpi(appraisal));
    toast.success('Kpi updated successfully');
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const deleteObjective = (id, appraisal) => async (dispatch) => {
  try {
    await axios.delete(`/appraisal/objectives/${id}/`);

    dispatch(getKpi(appraisal));
    toast.success('Kpi deleted successfully');
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const initiateKpi = (id) => async (dispatch) => {
  try {
    await axios.post(`/appraisal/${id}/send_kpi/`);
    dispatch(getKpi(id));

    toast.success('Kpi initiated successfully');
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const getEmployeesAppraisal =
  (paginate, page = 1, pagesize = 50) =>
  async (dispatch) => {
    const url = paginate || `/appraisal/employee_kpi/?page=${page}&page_size=${pagesize}`;
    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getEmployeesAppraisalForEmployee =
  (appraisalId, page = 1, pagesize = 50) =>
  async (dispatch) => {
    const url = `/appraisal/employee_kpi/?kpi__appraisal__id=${appraisalId}&page=${page}&page_size=${pagesize}&kpi__category=EMPLOYEE`;

    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getSingleEmployeesAppraisalKpi =
  (appraisalId, employee_id) => async (dispatch) => {
    const url = `/appraisal/employee_kpi/?employee__id=${employee_id}&kpi__appraisal__id=${appraisalId}`;

    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getEmployeesAppraisalSearch = (appraisalId, search) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/appraisal/employee_kpi/?kpi__appraisal__id=${appraisalId}&search=${search}`
    );
    dispatch({
      type: actionTypes.GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const getEmployeesAppraisalForEmployees =
  (appraisalId, page = 1, pagesize = 50) =>
  async (dispatch) => {
    const url = `/appraisal/employee_kpi/?kpi__appraisal__id=${appraisalId}&page=${page}&page_size=${pagesize}&category="EMPLOYEE"`;

    try {
      const response = await axios.get(url);
      dispatch({
        type: actionTypes.GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getEmployeesAppraisalSearches =
  (appraisalId, search) => async (dispatch) => {
    try {
      const response = await axios.get(
        `/appraisal/employee_kpi/?kpi__appraisal__id=${appraisalId}&search=${search}&category="EMPLOYEE"`
      );
      dispatch({
        type: actionTypes.GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS,
        payload: response.data,
      });
    } catch ({ response }) {
      if (response?.status) {
        generateError(response?.data?.message || response?.data?.errors);
        return;
      }
      return toast.error('Network Error');
    }
  };

export const getEmployeesAppraisalSingle = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/appraisal/employee_kpi/${id}`);
    dispatch({
      type: actionTypes.GET_EMPLOYEES_APPRAISAL_SINGLE_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const getEmployeesAppraisalOrdering = (appraisalId, value) => async (dispatch) => {
  try {
    const response = await axios.get(
      `/appraisal/employee_kpi/?kpi__appraisal__id=${appraisalId} & ordering=${value}`
    );
    dispatch({
      type: actionTypes.GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS,
      payload: response.data,
    });
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const resendEmployeesAppraisalForEmployees = (id, data) => async (dispatch) => {
  try {
    await axios.post(`/appraisal/${id}/resend_send_kpi/`, data);
    toast.success('Appraisal resent successfully');
    dispatch(getEmployeesAppraisalForEmployees(id));
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

// Might need to create on for the leve function
export const getSupervisorEmployeeLeave = (employeeId) => async (dispatch) => {
  const url = `/leave/${employeeId}/`;
  try {
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_SCHEDULED_LEAVE_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};

export const updateEmployeeLeaveForSupervisor = (id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(`/leave/${id}/supervisor_update/`, data);
    dispatch({
      type: actionTypes.GET_SCHEDULED_LEAVE_SUCCESS,
      payload: response.data,
    });

    toast.success('Leave updated successfully');
    // window.location.href = '/appraisal-success';
  } catch ({ response }) {
    if (response?.status) {
      generateError(response?.data?.message || response?.data?.errors);
      return;
    }
    return toast.error('Network Error');
  }
};
