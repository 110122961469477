export const GET_APPRAISAL_START = 'GET_APPRAISAL_START';
export const GET_APPRAISAL_SUCCESS = 'GET_APPRAISAL_SUCCESS';
export const GET_APPRAISAL_FAIL = 'GET_APPRAISAL_FAIL';

export const GET_SINGLE_APPRAISAL_START = 'GET_SINGLE_APPRAISAL_START';
export const GET_SINGLE_APPRAISAL_SUCCESS = 'GET_SINGLE_APPRAISAL_SUCCESS';
export const GET_SINGLE_APPRAISAL_FAIL = 'GET_SINGLE_APPRAISAL_FAIL';

export const CREATE_APPRAISAL_START = 'CREATE_APPRAISAL_START';
export const CREATE_APPRAISAL_SUCCESS = 'CREATE_APPRAISAL_SUCCESS';
export const CREATE_APPRAISAL_FAIL = 'CREATE_APPRAISAL_FAIL';

export const CREATE_KPI_OBJECTIVES_START = 'CREATE_KPI_OBJECTIVES_START';
export const CREATE_KPI_OBJECTIVES_SUCCESS = 'CREATE_KPI_OBJECTIVES_SUCCESS';
export const CREATE_KPI_OBJECTIVES_FAIL = 'CREATE_KPI_OBJECTIVES_FAIL';

export const GET_KPI_OBJECTIVES_START = 'GET_KPI_OBJECTIVES_START';
export const GET_KPI_OBJECTIVES_SUCCESS = 'GET_KPI_OBJECTIVES_SUCCESS';
export const GET_KPI_OBJECTIVES_FAIL = 'GET_KPI_OBJECTIVES_FAIL';

export const GET_EMPLOYEES_APPRAISAL_LIST_START = 'GET_EMPLOYEES_APPRAISAL_LIST_START';
export const GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS =
  'GET_EMPLOYEES_APPRAISAL_LIST_SUCCESS';
export const GET_EMPLOYEES_APPRAISAL_LIST_FAIL = 'GET_EMPLOYEES_APPRAISAL_LIST_FAIL';

export const GET_EMPLOYEES_APPRAISAL_SINGLE_START =
  'GET_EMPLOYEES_APPRAISAL_SINGLE_START';
export const GET_EMPLOYEES_APPRAISAL_SINGLE_SUCCESS =
  'GET_EMPLOYEES_APPRAISAL_SINGLE_SUCCESS';
export const GET_EMPLOYEES_APPRAISAL_SINGLE_FAIL = 'GET_EMPLOYEES_APPRAISAL_SINGLE_FAIL';

export const INITIATE_APPRAISAL_START = 'INITIATE_APPRAISAL_START';
export const INITIATE_APPRAISAL_SUCCESS = 'INITIATE_APPRAISAL_SUCCESS';
export const INITIATE_APPRAISAL_FAIL = 'INITIATE_APPRAISAL_FAIL';

export const GET_CURRENT_APPRAISAL_START = 'GET_CURRENT_APPRAISAL_START';
export const GET_CURRENT_APPRAISAL_SUCCESS = 'GET_CURRENT_APPRAISAL_SUCCESS';
export const GET_CURRENT_APPRAISAL_FAIL = 'GET_CURRENT_APPRAISAL_FAIL';

export const GET_SCHEDULED_LEAVE_START = 'GET_SCHEDULED_LEAVEL_START';
export const GET_SCHEDULED_LEAVE_SUCCESS = 'GET_SCHEDULED_LEAVE_SUCCESS';
export const GET_SCHEDULED_LEAVE_FAIL = 'GET_SCHEDULED_LEAVE_FAIL';
